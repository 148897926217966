import { useTheme } from "@chakra-ui/react"
import React from "react"
import { DebugLayout } from "../../components/debug/DebugLayout"
import { Json } from "../../components/Json"

export default function Theme() {
  const theme = useTheme()
  return (
    <DebugLayout title="Chakra UI Theme">
      <Json isAdvanced expandDepth={1}>
        {theme}
      </Json>
    </DebugLayout>
  )
}
